

import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import application from './modules/application';
import auth from './modules/auth';
import collector from './modules/collector';
import documents from './modules/documents';
import sharings from './modules/sharings';
import user from './modules/user';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({

  modules: {
    application,
    auth,
    collector,
    documents,
    user,
    sharings,
  },

  strict: debug,
});

Vue.$store = store;

export default store;
