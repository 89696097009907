

export const CHECK = 'CHECK';
export const CHECK_FRANCECONNECT = 'CHECK_FRANCECONNECT';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_MIPIH = 'SET_MIPIH';
export const FRANCE_CONNECT_TOKEN = 'FRANCE_CONNECT_TOKEN';

export default {
  CHECK,
  CHECK_FRANCECONNECT,
  LOGIN,
  LOGOUT,
  SET_MIPIH,
  FRANCE_CONNECT_TOKEN,
};
