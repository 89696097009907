

/* eslint-disable no-param-reassign */

export default {
  /**
   * Mutation to update the store with the fetched documents.
   *
   * @param {Object} state      The current state of the store.
   * @param {Array}  sharings     The fetched documents.
   */
  SET_USER_Shares(state, data) {
    state.userShares = data;
  },
};
