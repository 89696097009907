

import * as types from './types';

export default {
  openSnackbar({ commit }, payload) {
    commit(types.OPEN_SNACKBAR, payload);
    // after param time close snack bar
    setTimeout(() => {
      commit('CLOSE_SNACKBAR');
    }, payload.time * 1000);
  },
  closeSnackbar({ commit }, payload) {
    commit(types.CLOSE_SNACKBAR, payload);
  },
  addBreadcrumb({ commit }, payload) {
    commit(types.ADD_BREADCRUMB, payload);
  },
  removeLastBreadcrumb({ commit }) {
    commit(types.REMOVE_LAST_BREADCRUMB);
  },
  setBreadcrumbs({ commit }, payload) {
    commit(types.SET_BREADCRUMBS, payload);
  },
  resetBreadcrumbs({ commit }) {
    commit(types.RESET_BREADCRUMBS);
  },
  toggleSideMenu({ commit }) {
    commit(types.TOGGLE_SIDE_MENU);
  },
  openSideMenu({ commit }) {
    commit(types.OPEN_SIDE_MENU);
  },
  closeSideMenu({ commit }) {
    commit(types.CLOSE_SIDE_MENU);
  },
  startLoading({ commit }) {
    commit(types.LOADING_START);
  },
  finishLoading({ commit }) {
    commit(types.LOADING_FINISH);
  },
  resetApplication({ commit }) {
    commit(types.SET_BREADCRUMBS, []);
  },
};
