
/* eslint-disable no-param-reassign */

import {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  SET_BREADCRUMBS,
  RESET_BREADCRUMBS,
  ADD_BREADCRUMB,
  REMOVE_LAST_BREADCRUMB,
  TOGGLE_SIDE_MENU,
  OPEN_SIDE_MENU,
  CLOSE_SIDE_MENU,
  LOADING_START,
  LOADING_FINISH,
} from './types';

export default {
  [OPEN_SNACKBAR](state, content) {
    state.snackbar.message = content.message;
    state.snackbar.centered = content.centered !== undefined;
    state.snackbar.type = content.type;
    state.snackbar.active = true;
  },

  [CLOSE_SNACKBAR](state) {
    state.snackbar.message = '';
    state.snackbar.centered = false;
    state.snackbar.type = undefined;
    state.snackbar.active = false;
  },

  [ADD_BREADCRUMB](state, payload) {
    state.breadcrumbs.push(payload);
  },

  [REMOVE_LAST_BREADCRUMB](state) {
    state.breadcrumbs.pop();
  },

  [SET_BREADCRUMBS](state, payload) {
    state.breadcrumbs = payload;
  },

  [RESET_BREADCRUMBS](state) {
    state.breadcrumbs = [];
  },

  [TOGGLE_SIDE_MENU](state) {
    state.sideMenu.opened = !state.sideMenu.opened;
  },

  [OPEN_SIDE_MENU](state) {
    state.sideMenu.opened = true;
  },

  [CLOSE_SIDE_MENU](state) {
    state.sideMenu.opened = false;
  },

  [LOADING_START](state) {
    state.loading.count += 1;
    state.loading.display = true;
  },

  [LOADING_FINISH](state) {
    state.loading.count -= 1;
    if (state.loading.count === 0) {
      state.loading.display = false;
    }
  },
};
