import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocale() {
  const locales = require.context('../locales', false, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  const dateTimeFormats = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).messages;
      dateTimeFormats[locale] = locales(key).dateTimeFormats;
    }
  });
  return { messages, dateTimeFormats };
}
const { messages, dateTimeFormats } = loadLocale();

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'fr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
  messages,
  dateTimeFormats,
});
Vue.$i18n = i18n;

export default i18n;
