

export default {
  authenticated: false,
  session: {
    token: undefined,
    login: undefined,
    guest: undefined,
    askForNewCgu: undefined,
  },
  isMIPIH: false,
  franceConnectToken: undefined,
};
