import Vue from 'vue';

export default function () {
  Vue.$store.watch(
    () => Vue.$store.getters['user/userCss'],
    (userCss) => {
      if (userCss) {
        const elementExists = document.getElementById('custom-css');
        if (elementExists) {
          document.head.removeChild(elementExists);
        }
        const cssTag = document.createElement('link');
        cssTag.setAttribute('id', 'custom-css');
        cssTag.setAttribute('rel', 'stylesheet');
        cssTag.setAttribute('type', 'text/css');
        cssTag.setAttribute('href', userCss);
        cssTag.async = true;
        document.head.appendChild(cssTag);
      }
    },
  );
}
