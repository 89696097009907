import Vue from 'vue';

/* DOSSIER ET FICHIERS */

class DocumentApi {
  static convertToImage(documentId) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      documentId,
    };
    return Vue.$http
      .post('/index.php?api=User&a=getFoldersAndFiles', data)
      .then(response => response.data);
  }


  static getLastDoc(limit) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      limit,
    };
    return Vue.$http
      .post('/edpDoc/getLast', data)
      .then(response => response.data);
  }

  /*
  Récupérer le contenu d'un fichier en fonction de son id :
  curl -d "{\"sessionId\":\"ba81fb7056592e69314a48161b8cbcf2\",\"documentId\":\"f46d4623-466d-490e-9211-f15ace5a4dc3\"}" "https://new.dev.edocperso.fr/edocPerso/V1/edpDoc/getContent"
   */
  static getContent(documentId) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      documentId,
    };
    return Vue.$http
      .post('/edpDoc/getContent', data,
        {
          responseType: 'arraybuffer',
          headers: { Accept: 'application/octet-stream' },
        })
      .then(response => response.data);
  }


  static getMultipleContent(documentIds) {
    const docIdsField = btoa(JSON.stringify(documentIds));
    return Vue.$http
      .get(`/edpDoc/getMultipleContent?sessionId=${Vue.$store.state.auth.session.token}&documentIds=${docIdsField}&download=1`,
        {
          responseType: 'arraybuffer',
          headers: { Accept: 'application/octet-stream' },
        })
      .then(response => response.data);
  }


  static markAsRead(documentId, isRead) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      documentId,
      isNew: !isRead,
    };
    return Vue.$http
      .post('/edpDoc/update', data)
      .then(response => response.data);
  }

  static save(data) {
    return Vue.$http
      .post('/index.php?api=UserDocument&a=update', data)
      .then(response => response.data);
  }

  /*
    Ajouter/enlever un document des favoris :
  */
  static updateIsFavorite(documentId, isFavorite) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      documentId,
      isFavorite,
    };
    return Vue.$http
      .post('/edpDoc/update', data)
      .then(response => response.data);
  }

  /*
    Renommer un document :
   */
  static rename(documentId, description) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      documentId,
      description,
    };
    return Vue.$http
      .post('/edpDoc/update', data)
      .then(response => response.data);
  }

  static create({ json, fileContent }) {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: process.env.VUE_APP_ARCHIVE_TIMEOUT,
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(json));
    formData.append('file', fileContent);

    return Vue.$http
      .post('/edpDoc/create', formData, config)
      .then(response => response.data);
  }

  /*
    Supprimer un document :
   */
  static deleteById(documentId) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      documentId,
    };
    return Vue.$http
      .post('/edpDoc/delete', data)
      .then(response => response.data);
  }

  /*
    Déplacer un document :
   */
  static move(documentId, folderId) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      documentId,
      folderId,
    };

    return Vue.$http
      .post('/edpDoc/update', data)
      .then(response => response.data);
  }
}

export default DocumentApi;
