

import Vue from 'vue';
import Axios from 'axios';
import store from '@/store';

const instance = Axios.create();
instance.defaults.baseURL = `${process.env.VUE_APP_API_LOCATION}/`;
instance.defaults.headers.common.Accept = 'application/json';
instance.defaults.timeout = process.env.VUE_APP_TIMEOUT;
instance.interceptors.response.use(
  (response) => {
    if (response.data.code === 6) {
      // en cas de session expirée ou modifiée dans le local storage, le logout retourne une erreur 6 session invalide
      // cet interceptor cause une boucle de logout sans cette condition
      if (response.request.responseURL.endsWith('/logout')) {
        const newResponse = response;
        newResponse.data.code = 0;
        return newResponse;
      }
      store.dispatch('auth/logout');
      return null;
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch('auth/logout');
    }

    return Promise.reject(error);
  },
);

// Bind Axios to Vue.
Vue.$http = instance;
Object.defineProperty(Vue.prototype, '$http', {
  get() {
    return instance;
  },
});
