import Vue from 'vue';

class AuthApi {
  static login({ username, password }) {
    const data = {
      login: username,
      password,
    };
    return Vue.$http.post('/login', data)
      .then(response => response.data);
  }

  static checkActivationCode({ firstName, lastName, activationCode }) {
    const data = {
      firstName,
      lastName,
      activationCode,
    };
    return Vue.$http
      .post('/authenticate/checkActivationCode', data)
      .then(response => response.data);
  }


  static logout() {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
    };
    return Vue.$http
      .post('/edpUser/logout', data)
      .then(response => response.data);
  }
}

export default AuthApi;
