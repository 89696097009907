import Vue from 'vue';

class FolderApi {
  static findAll() {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
    };
    return Vue.$http.post('/edpUser/getFoldersAndFiles', data).then(response => response.data);
  }


  static FindShareGuest() {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
    };
    return Vue.$http.post('/edpDocumentSharing/getSharedFiles', data).then(response => response.data);
  }

  /*
    Créer un dossier :
  */
  static create(params) {
    let data;
    if (params.category === undefined) {
      data = {
        sessionId: Vue.$store.state.auth.session.token,
        parentNodeId: params.FolderID,
        name: params.NewFolderName,
      };
    } else {
      data = {
        sessionId: Vue.$store.state.auth.session.token,
        parentNodeId: params.FolderID,
        name: params.NewFolderName,
        category: params.category,
      };
    }
    return Vue.$http.post('/edpFolder/create', data).then(response => response.data);
  }

  /*
    Renommer un dossier :
   */
  static rename(folderId, name) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      folderId,
      name,
    };
    return Vue.$http.post('/edpFolder/update', data).then(response => response.data);
  }

  /*
     Supprimer un dossier :
   */
  static deleteById(folderId) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      folderId,
    };
    return Vue.$http.post('/edpFolder/delete', data).then(response => response.data);
  }

  /*
    Déplacer un dossier :
   */
  static move(folderId, name, parentNodeId) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      folderId,
      name,
      parentNodeId,
    };
    return Vue.$http.post('/edpFolder/update', data).then(response => response.data);
  }
}

export default FolderApi;
