

export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const OPEN_SIDE_MENU = 'OPEN_SIDE_MENU';
export const CLOSE_SIDE_MENU = 'CLOSE_SIDE_MENU';
export const LOADING_START = 'LOADING_START';
export const LOADING_FINISH = 'LOADING_FINISH';
export const ADD_BREADCRUMB = 'ADD_BREADCRUMB';
export const REMOVE_LAST_BREADCRUMB = 'REMOVE_LAST_BREADCRUMB';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const RESET_BREADCRUMBS = 'RESET_BREADCRUMBS';

export default {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  TOGGLE_SIDE_MENU,
  OPEN_SIDE_MENU,
  CLOSE_SIDE_MENU,
  LOADING_START,
  LOADING_FINISH,
  ADD_BREADCRUMB,
  REMOVE_LAST_BREADCRUMB,
  SET_BREADCRUMBS,
  RESET_BREADCRUMBS,
};
