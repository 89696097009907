import Axios from 'axios';

export const HTTP_EDOCPERSO = Axios.create({
  baseURL: `${process.env.API_URL}`,
  headers: {
    common: {
      Accept: 'application/json',
    },
  },
});

export const HTTP_CONF = Axios.create({
  baseURL: '/static',
  timeout: process.env.TIMEOUT,
  headers: {
    common: {
      Accept: 'application/json',
    },
  },
});
