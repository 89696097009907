import Vue from 'vue';

class CollectorApi {
  static findAll() {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
    };
    return Vue.$http
      .post('/collectors/getAll', data)
      .then(response => response.data);
  }

  static activate(subscriptionId, signatureCode) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      subscriptionId,
      signatureCode,
    };
    return Vue.$http
      .post('/edpUserCollector/=activate', data)
      .then(response => response.data);
  }
}

export default CollectorApi;
