/* eslint-disable no-param-reassign */
export default {
  SET_COLLECTORS(state, data) {
    state.collectors = data;
  },
  SET_USER_COLLECTORS(state, data) {
    state.userCollectors = data;
  },
  UPDATE_USER_COLLECTORS_PROVIDER_SUBSCRIPTION(state, { providerId, subscriptions }) {
    const collector = state.userCollectors.find(col => col.id === providerId);
    if (collector !== undefined) {
      collector.subscriptions = subscriptions;
    }
  },

  TIMEOUT_SUBSCRIPTION(state, subscriptionId) {
    state.timeouts.push(subscriptionId);
  },

  TIMEOUT_SUBSCRIPTION_REMOVE(state) {
    state.timeouts.shift();
  },
};
