

import Vue from 'vue';
import ShareApi from '@/api/ShareApi';

export default {
  all({ dispatch, commit }) {
    dispatch('application/startLoading', null, { root: true });
    return ShareApi.findAll()
      .then((response) => {
        if (response.code === 0) {
          commit('SET_USER_Shares', response.content.listDocSharing);
          dispatch('application/finishLoading', null, { root: true });
          return (response.content).listDocSharing;
        }
        dispatch(
          'application/openSnackbar',
          {
            message: `${Vue.$i18n.t('sharing.messages.retrieveAll.error')} : <br> ${response}`, // `Erreur lors de la récupération de la liste de documents: <br> ${error}`,
            type: 'error',
          },
          { root: true },
        );
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/finishLoading', null, { root: true });
        commit('SET_USER_Shares', []);
        dispatch(
          'application/openSnackbar',
          {
            message: `${Vue.$i18n.t('sharing.messages.retrieveAll.error')} : <br> ${error}`, // `Erreur lors de la récupération de la liste de documents: <br> ${error}`,
            type: 'error',
          },
          { root: true },
        );
        return Promise.reject(error);
      });
  },

  create({ dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });
    return ShareApi.create(payload)
      .then((response) => {
        dispatch('application/finishLoading', null, { root: true });
        if (response.code === 0) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('sharing.messages.creation.success'),
              type: 'success',
              time: 10,
              centered: true,
            },
            { root: true },
          );
          return Promise.resolve(response);
        }
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('sharing.messages.creation.error'),
            type: 'error',
            time: 10,
            centered: true,
          },
          { root: true },
        );
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('sharing.messages.creation.error'),
            time: 10,
            type: 'error',
            centered: true,
          },
          { root: true },
        );
        return Promise.reject(error);
      });
  },

  findById({ dispatch }, sharingId) {
    dispatch('application/startLoading', null, { root: true });
    return ShareApi.findById(sharingId)
      .then((response) => {
        dispatch('application/finishLoading', null, { root: true });
        if (response.code === 0) {
          return Promise.resolve(response.content.edpDocs);
        }
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('sharing.messages.findById.error'),
            type: 'error',
            time: 10,
            centered: true,
          },
          { root: true },
        );
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('sharing.messages.findById.error'),
            time: 10,
            type: 'error',
            centered: true,
          },
          { root: true },
        );
        return Promise.reject(error);
      });
  },

  update({ dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });
    // eslint-disable-next-line
    return ShareApi.update(payload.sharingId, payload.recipientMail, payload.recipientName, payload.startingDate, payload.sharingDuration)
      .then((response) => {
        dispatch('application/finishLoading', null, { root: true });
        if (response.code === 0) {
          return Promise.resolve(response.content);
        }
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('sharing.messages.findById.error'),
            type: 'error',
            time: 10,
            centered: true,
          },
          { root: true },
        );
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('sharing.messages.findById.error'),
            time: 10,
            type: 'error',
            centered: true,
          },
          { root: true },
        );
        return Promise.reject(error);
      });
  },
  delete({ dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });
    return ShareApi.deleteById(payload)
      .then((response) => {
        dispatch('application/finishLoading', null, { root: true });
        if (response.code === 0) {
          return Promise.resolve(response.content);
        }
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('sharing.messages.findById.error'),
            type: 'error',
            time: 10,
            centered: true,
          },
          { root: true },
        );
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/finishLoading', null, { root: true });
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('sharing.messages.findById.error'),
            time: 10,
            type: 'error',
            centered: true,
          },
          { root: true },
        );
        return Promise.reject(error);
      });
  },
  deleteMultiple(promises) {
    Promise.all(promises);
  },
  EmptyShares({ commit }) {
    commit('SET_USER_Shares', []);
  },
};
