

export default [
  // Login
  {
    path: '/login',
    name: 'login.index',
    component: () => import('@/pages/login/Index.vue'),
    // If the user needs to be a guest to view this page
    meta: {
      guest: true,
    },
  },

  {
    path: '/login/:token',
    name: 'login.token',
    props: true,
  },
  {
    path: '/login/:token/:franceConnectToken',
    name: 'login.token.withFranceConnect',
    props: true,
  },

  {
    path: '/guest',
    name: 'guest.index',
    component: () => import('@/pages/guest/Index.vue'),
    meta: {
      guest: true,
      auth: true,
    },
  },
  {
    path: '/maintenance',
    name: 'maintenance.index',
    component: () => import('@/pages/maintenance/Index.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/guest/details',
    name: 'guest.details',
    props: true,
    component: () => import('@/pages/guest/Viewer.vue'),
    // If the user needs to be authenticated to view this page.
    meta: {
      guest: true,
      auth: true,
    },
  },
  {
    path: '/sharing-login/:token',
    name: 'login.guest',
    props: true,
    // component: () => import('@/pages/login/AddTokenToStore.vue'),
    // If the user needs to be a guest to view this page
    meta: {
      guest: true,
    },
  },

  {
    path: '/logout',
    name: 'logout',
  },

  // Home
  {
    path: '/',
    name: 'home.index',
    component: () => import('@/pages/home/Index.vue'),

    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
    },
  },

  {
    path: '/sharings',
    name: 'sharings.index',
    component: () => import('@/pages/sharings/Index.vue'),
    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
    },
  },

  {
    path: '/sharings/details',
    name: 'sharings.show',
    props: true,
    component: () => import('@/pages/sharings/Show.vue'),
    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
    },
  },
  {
    path: '/sharings/create',
    name: 'sharings.create',
    props: true,
    component: () => import('@/pages/sharings/Create.vue'),
    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
    },
  },

  // Documents
  {
    path: '/folders',
    name: 'folders.index',
    props: true,
    component: () => import('@/pages/documents/Index.vue'),
    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
    },
  },
  {
    path: '/folders/details',
    name: 'document.details',
    props: true,
    component: () => import('@/pages/documents/Preview.vue'),
    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
    },
  },
  // Profile

  {
    path: '/profile',
    name: 'profile.index',
    component: () => import('@/pages/profile/Index.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/profile/details',
    name: 'profile.details',
    props: true,
    component: () => import('@/pages/profile/AdminViewer.vue'),
    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
    },
  },
  {
    path: '/profile/fc-callback',
    name: 'profile.index.fcCallback',
    props: {
      isFcCallback: true,
    },
    component: () => import('@/pages/profile/Index.vue'),
    meta: {
      auth: true,
    },
  },

  // Collectors
  {
    path: '/collectors',
    name: 'collectors',
    component: () => import('@/pages/collectors/maintenance.vue'),
    meta: {
      auth: true,
    },
  },

  {
    path: '/*',
    redirect: '/',
  },
];
