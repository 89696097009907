import Vue from 'vue';


class UserAgentsApi {
  /* recuperer tous les collecteurs de l'utilisateur */
  static findAll() {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
    };
    return Vue.$http
      .post('/edpUserCollector/getAll', data)
      .then(response => response.data);
  }

  /* enregistrer un collecteur pour l'utilisateur */
  static add(providerId, inputs) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      providerId,
      inputs,
    };
    return Vue.$http
      .post('/edpUserCollector/activate', data)
      .then(response => response.data);
  }

  /* Mettre a jour les données d'un colleteur enregistré */
  static update(providerId, subscriptionId, inputs) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      providerId,
      subscriptionId,
      inputs,
    };
    return Vue.$http
      .post('/edpUserCollector/update', data)
      .then(response => response.data);
  }

  /* Récupérer les subscription d'un provider pour un utilisateur */
  static getProviderSubscriptions(providerId) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      providerId,
    };
    return Vue.$http
      .post('/edpUserCollector/getProviderSubscriptions', data)
      .then(response => response.data);
  }

  /* Supprimer un collecteur enregistré */
  static deleteById(subscriptionId) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      subscriptionId,
    };
    return Vue.$http
      .post('/edpUserCollector/remove', data)
      .then(response => response.data);
  }

  static sendAuthCode(subscriptionId, code) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      subscriptionId,
      code,
    };
    return Vue.$http
      .post('/edpUserCollector/setMfaCode', data)
      .then(response => response.data);
  }

  static loadFiles(providerId, subscriptionIds) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      providerId,
      subscriptionIds,
    };
    return Vue.$http
      .post('/edpUserCollector/launch', data)
      .then(response => response.data);
  }
}

export default UserAgentsApi;
