export default function checkNew(documents) {
  const docs = documents;
  let isNew = 0;
  for (let i = 0; i < docs.length; i += 1) {
    isNew = 0;
    // eslint-disable-next-line
    const children = docs[i].children;
    for (let j = 0; children !== undefined && j < children.length; j += 1) {
      if (children[j].isNew && children[j].type !== 'folder') {
        isNew += 1;
      }
      if (children[j].isNew && children[j].type === 'folder') {
        isNew += children[j].isNew;
      }
      // eslint-disable-next-line
      checkNew(children).isNew;
    }
    if (isNew) {
      docs[i].isNew = isNew;
    }
  }
  return { docs, isNew };
}
