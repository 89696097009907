

export default {
  all: [],
  allShares: [],
  lastDoc: [],
  adminDocId: '',
  pagination: {
    totalCount: 0,
    totalPages: 1,
    currentPage: 1,
    limit: 20,
  },
};
