import Vue from 'vue';

class PetalsApi {
  //     Récupération du compteur de pétales
  static getCounter() {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
    };
    return Vue.$http.post('/edpUser/getCounter', data).then(response => response.data);
  }
}

export default PetalsApi;
