

import Vue from 'vue';
import FolderApi from '@/api/FolderApi';
import documentApi from '@/api/DocumentApi';
import * as types from './mutation-types';
import checkNew from '../utils';

/**
 * Action fired when all documents will be fetched.
 *
 * @param {function} dispatch Dispatch function to trigger actions.
 * @param {function} commit Commit function to update the store.
 * @param {function} fn     Callback to edit the parameters on the api.
 */

// .catch(() => {
//   dispatch(
//     'application/openSnackbar',
//     {
//       message: Vue.$i18n.t('collector.get.error'),
//       time: 5,
//       centered: true,
//     },
//     { root: true },
//   );
//   commit('SET_COLLECTORS', []);
// })

const all = ({ dispatch, commit }, fn = null) => {
  if (typeof fn === 'function') {
    fn(FolderApi);
  }
  dispatch('application/startLoading', null, { root: true });
  return FolderApi.findAll()
    .then((response) => {
      dispatch('application/finishLoading', null, { root: true });
      if (response.code === 0) {
        // console.log('boo');
        // checkNew must be called twice, once to map vaalues, once to attribute values
        checkNew(response.content);
        commit(types.ALL, checkNew(response.content).docs);
        return Promise.resolve(response.content);
      }
      return Promise.reject(response);
    })
    .catch((error) => {
      commit(types.ALL, []);
      dispatch('application/finishLoading', null, { root: true });
      dispatch(
        'application/openSnackbar',
        {
          message: Vue.$i18n.t('document.error'),
          type: 'error',
        },
        { root: true },
      );
      return Promise.reject(error);
    });
};
const allShares = ({ dispatch, commit }, fn = null) => {
  if (typeof fn === 'function') {
    fn(FolderApi);
  }
  dispatch('application/startLoading', null, { root: true });
  return FolderApi.FindShareGuest()
    .then((response) => {
      // console.log(response.content);
      dispatch('application/finishLoading', null, { root: true });
      if (response.code === 0) {
        // checkNew must be called twice, once to map vaalues, once to attribute values

        commit(types.ALL_SHARES, response.content);

        return Promise.resolve(response.content);
      }
      return Promise.reject(response);
    })
    .catch((error) => {
      commit(types.ALL_SHARES, []);
      dispatch('application/finishLoading', null, { root: true });
      dispatch(
        'application/openSnackbar',
        {
          message: Vue.$i18n.t('document.error'),
          type: 'error',
        },
        { root: true },
      );
      return Promise.reject(error);
    });
};

const getLastDoc = ({ dispatch, commit }, limit) => {
  dispatch('application/startLoading', null, { root: true });
  return documentApi
    .getLastDoc(limit)
    .then((response) => {
      if (response.code === 0) {
        commit(types.SET_LAST_DOC, response.content.edpDocs);
        return Promise.resolve(response.content.edpDocs);
      }
      return Promise.reject(response);
    })
    .catch((error) => {
      commit(types.ALL, []);
      dispatch(
        'application/openSnackbar',
        {
          message: Vue.$i18n.t('document.error'),
          type: 'error',
        },
        { root: true },
      );
      return Promise.reject(error);
    })
    .finally(() => {
      dispatch('application/finishLoading', null, { root: true });
    });
};

/**
 * Action fired when a document will be created.
 *
 * @param {function} dispatch Dispatch function to trigger actions.
 * @param {function} commit Commit function to update the store.
 * @param {Object}   document   The document that will be created.
 */
// eslint-disable-next-line
const create = ({ dispatch, commit }, document) => {
  dispatch('application/startLoading', null, { root: true });

  return documentApi
    .create(document)
    .then((response) => {
      dispatch('application/finishLoading', null, { root: true });
      dispatch(
        'application/openSnackbar',
        {
          message: Vue.$i18n.t('document.create'),
          type: 'success',
        },
        { root: true },
      );
      return response;
    })
    .catch((error) => {
      dispatch('application/finishLoading', null, { root: true });
      dispatch(
        'application/openSnackbar',
        {
          message: Vue.$i18n.t('document.create.error'),
          type: 'error',
        },
        { root: true },
      );
      return Promise.reject(error);
    });
};

const setCurrentPage = ({ commit }, currentPage) => {
  commit(types.SET_CURRENT_PAGE, currentPage);
};

export default {
  all,
  allShares,
  getLastDoc,
  checkNew,
  create,
  setCurrentPage,
  deleteMultiple(promises) {
    Promise.all(promises);
  },
  deleteMyFile({ dispatch }, fileId) {
    return documentApi
      .deleteById(fileId)
      .then((response) => {
        if (response.code === 0) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('document.delete.success'),
              time: 5,
              centered: true,
              type: 'success',
            },
            { root: true },
          );
        } else if (response.code === 18) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('document.delete.prevented'),
              type: 'error',
              time: 10,
              centered: true,
            },
            { root: true },
          );
        } else if (response.code === 1818) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('document.exit.not.deleteable'),
              type: 'error',
              time: 10,
              centered: true,
            },
            { root: true },
          );
        }
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('document.delete.error'),
            time: 5,
            centered: true,
          },
          { root: true },
        );
      });
  },

  renameFolder({ dispatch }, payload) {
    return FolderApi.rename(payload.folderID, payload.name)
      .then((response) => {
        if (response) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('document.rename.success'),
              time: 5,
              centered: true,
              type: 'success',
            },
            { root: true },
          );
        }
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('document.rename.error'),
            time: 5,
            centered: true,
          },
          { root: true },
        );
      });
  },
  renameFile({ dispatch }, payload) {
    return documentApi
      .rename(payload.fileID, payload.name)
      .then((response) => {
        if (response) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('document.rename.file.success'),
              time: 5,
              centered: true,
              type: 'success',
            },
            { root: true },
          );
        }
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('document.rename.file.error'),
            time: 5,
            centered: true,
          },
          { root: true },
        );
      });
  },

  downloadMyFile({ dispatch }, FileID) {
    dispatch('application/startLoading', null, { root: true });
    return documentApi
      .getContent(FileID)
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('document.download.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  downloadMultipleFiles({ dispatch }, fileIds) {
    dispatch('application/startLoading', null, { root: true });
    return documentApi
      .getMultipleContent(fileIds)
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('document.download.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  deleteMyFolder({ dispatch }, folderID) {
    return FolderApi.deleteById(folderID)
      .then((response) => {
        if (response.code === 0) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('document.delete.folder.success'),
              time: 5,
              centered: true,
              type: 'success',
            },
            { root: true },
          );
        } else if (response.code === 19) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('document.delete.folder.prevented'),
              time: 5,
              centered: true,
            },
            { root: true },
          );
        }
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('document.delete.folder.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      });
  },

  // eslint-disable-next-line
  moveFolder({}, params) {
    return FolderApi.move(params.folderID, params.name, params.id);
  },
  // eslint-disable-next-line
  moveFile({}, params) {
    return documentApi.move(params.itemid, params.id);
  },
  createMyFolder({ dispatch }, payload) {
    // dispatch('application/startLoading', null, { root: true });
    //  console.log(payload);
    return FolderApi.create(payload)
      .then((response) => {
        if (response) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('document.add.success'),
              type: 'success',
              time: 5,
              centered: true,
            },
            { root: true },
          );
        }
        return response;
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('document.add.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      });
  },
  //   }),);
  EmptyDocuments({ commit }) {
    commit(types.ALL, []);
    commit(types.SET_LAST_DOC, []);
  },
  EmptySahre({ commit }) {
    commit(types.ALL_SHARES, []);
  },
};
