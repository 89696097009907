
import CryptoJS from 'crypto-js';

export default function initZendeskJWT(userMail, userName) {
  function base64url(source) {
    // Encode in classical base64
    let encodedSource = CryptoJS.enc.Base64.stringify(source);

    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  }

  const header = {
    alg: 'HS256',
    typ: 'JWT',
    kid: process.env.VUE_APP_ZENDESK_APP_ID,
  };

  const data = {
    email: userMail,
    email_verified: true,
    exp: Date.now() + 60 * 60,
    external_id: userMail,
    name: userName,
    scope: 'user',
  };

  const token = [
    base64url(CryptoJS.enc.Utf8.parse(JSON.stringify(header))),
    base64url(CryptoJS.enc.Utf8.parse(JSON.stringify(data))),
  ].join('.');

  const signedToken = [
    token,
    base64url(CryptoJS.HmacSHA256(token, process.env.VUE_APP_ZENDESK_APP_SECRET)),
  ].join('.');

  window.zE('messenger', 'loginUser', callback => callback(signedToken));
}
