export default {
  user: state => state.user,
  userCss: (state) => {
    if (state.user && state.user.specificCss && state.user.specificCss.length > 0) {
      return `${process.env.VUE_APP_API_LOCATION}/${state.user.specificCss.replace('\\')}`;
    }
    return '';
  },
  adminDocs: state => state.adminDocs,
  services: state => state.services,
  usageDatas: state => state.usageDatas,
  petalCounter: state => state.petalCounter,
  adminDocid: state => state.adminDocid,
  dematStatus: state => state.dematStatus,
};
