

/* eslint-disable no-param-reassign */

// import Vue from 'vue';
import {
  CHECK, LOGIN, SET_MIPIH, LOGOUT, FRANCE_CONNECT_TOKEN, CHECK_FRANCECONNECT,
} from './mutation-types';

export default {
  /**
   * Mutation to check if the user is authenticated.
   *
   * @param {Object} state The current state of the store.
   * @param {Object} session The session infos.
   */
  [CHECK](state, session) {
    if (session) {
      state.authenticated = !!session.token;
    } else {
      state.authenticated = false;
    }

    if (state.authenticated) {
      state.session.token = session.token;
      state.session.login = session.login;
      state.session.guest = session.guest;
      // Vue.$http.defaults.headers.common.Authorization = `Bearer ${session.token}`;
    }
  },

  /**
   * Mutation to log the user in.
   *
   * @param {Object} state The current state of the store.
   * @param {Object} session The session infos.
   */
  [LOGIN](state, session) {
    state.authenticated = true;
    localStorage.clear();
    localStorage.setItem('ep-session', JSON.stringify(session));
    state.session.token = session.token;
    state.session.login = session.login;
    state.session.guest = session.guest;
    state.session.askForNewCgu = session.askForNewCgu;
    // Vue.$http.defaults.headers.common.Authorization = `Bearer ${session.token}`;
    // console.log(session);
  },

  /**
   * Mutation to log the user out.
   *
   * @param {Object} state The current state of the store.
   */
  [LOGOUT](state) {
    state.authenticated = false;
    localStorage.clear();
    state.session.token = undefined;
    state.session.login = undefined;
    // Vue.$http.defaults.headers.common.Authorization = '';
    // Vue.$http.defaults.headers.common.Authorization = '';
  },

  [SET_MIPIH](state, isMIPIH) {
    state.isMIPIH = isMIPIH;
  },
  [FRANCE_CONNECT_TOKEN](state, fcToken) {
    localStorage.setItem('ep-fctoken', fcToken);
    state.franceConnectToken = fcToken;
  },
  [CHECK_FRANCECONNECT](state, fcToken) {
    if (fcToken !== undefined) {
      state.franceConnectToken = fcToken;
    }
  },
};
