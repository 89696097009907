import Vue from 'vue';

class ShareApi {
  static create({
    recipientName, recipientEmail, duration, fileIds,
  }) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      recipientName,
      recipientMail: recipientEmail,
      duration,
      fileIds,
    };
    return Vue.$http.post('/edpDocumentSharing/create', data).then(response => response.data);
  }

  static findAll() {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
    };
    return Vue.$http.post('/edpDocumentSharing/list', data).then(response => response.data);
  }

  static findById(id) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      sharingId: id,
    };
    return Vue.$http.post('/edpDocumentSharing/getDocuments', data).then(response => response.data);
  }

  static deleteById(id) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      sharingId: id,
    };
    return Vue.$http.post('/edpDocumentSharing/delete', data).then(response => response.data);
  }

  // Modification d'un partage
  static update(sharingId, recipientMail, recipientName, startingDate, sharingDuration) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      sharingId,
      recipientMail,
      recipientName,
      startingDate,
      sharingDuration,
    };

    return Vue.$http.post('/edpDocumentSharing/update', data).then(response => response.data);
  }
}

export default ShareApi;
