

/* eslint-disable no-param-reassign */

import {
  ALL, SET_CURRENT_PAGE, SET_LAST_DOC, ALL_SHARES,
} from './mutation-types';

export default {
  /**
   * Mutation to update the store with the fetched documents.
   *
   * @param {Object} state      The current state of the store.
   * @param {Array}  documents     The fetched documents.
   */
  [ALL](state, documents) {
    // eslint-disable-next-line
    state.all = documents.sort((a, b) => (a.name < b.name ? -1 : 1));
  },
  [ALL_SHARES](state, documents) {
    // eslint-disable-next-line
    state.allShares = documents;
  },
  [SET_CURRENT_PAGE](state, currentPage) {
    state.pagination.currentPage = currentPage;
  },
  [SET_LAST_DOC](state, documents) {
    state.lastDoc = documents;
  },
};
