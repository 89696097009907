

export const ALL = 'ALL';
export const ALL_SHARES = 'ALL_SHARES';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_LAST_DOC = 'SET_LAST_DOC';

export default {
  ALL,
  SET_CURRENT_PAGE,
  SET_LAST_DOC,
  ALL_SHARES,
};
